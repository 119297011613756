<template>
  <div>
    <h3 class="search__result__title">{{ totalResults }} Ergebnisse</h3>


    <div class="search__wrapper">
      <div class="search__filter">
        <div @click="activeTab = 'pages'" class="search__filter__item" :class="{ active: activeTab === 'pages' }">
          <span>Seiten</span>
          <span v-if="loading.pages" class="search__counter">?</span>
          <span v-else class="search__counter">{{ results.pages.length }}</span>
        </div>
        <div @click="activeTab = 'pitc_portfolio'" class="search__filter__item" :class="{ active: activeTab === 'pitc_portfolio' }">
          <span>Referenzen</span>
          <span v-if="loading.pitc_portfolio" class="search__counter">?</span>
          <span v-else class="search__counter">{{ results.pitc_portfolio.length }}</span>
        </div>
        <div @click="activeTab = 'posts'" class="search__filter__item" :class="{ active: activeTab === 'posts' }">
          <span>Blog</span>
          <span v-if="loading.posts" class="search__counter">?</span>
          <span v-else class="search__counter">{{ results.posts.length }}</span>
        </div>
        <div @click="activeTab = 'events'" class="search__filter__item" :class="{ active: activeTab === 'events' }">
          <span>Events</span>
          <span v-if="loading.events" class="search__counter">?</span>
          <span v-else class="search__counter">{{ results.events.length }}</span>
        </div>
        <div @click="activeTab = 'trainings'" class="search__filter__item" :class="{ active: activeTab === 'trainings' }">
          <span>Trainings</span>
          <span v-if="loading.trainings" class="search__counter">?</span>
          <span v-else class="search__counter">{{ results.trainings.length }}</span>
        </div>
      </div>
      <div class="search__results">
        <div v-if="activeTab === 'pages'" class="search-results-pages teaserbox__wrapper teaser--col--1">
          <InlineSpinner v-if="loading.pages"></InlineSpinner>
          <template v-else>
            <div v-for="item in results.pages" v-html="item.content" class="teaserbox__item__wrapper"></div>
          </template>
        </div>
        <div v-else-if="activeTab === 'pitc_portfolio'" class="search-results-reference reference__wrapper">
          <div class="teaserbox__wrapper">
            <InlineSpinner v-if="loading.pitc_portfolio"></InlineSpinner>
            <template v-else>
              <component v-for="item in results.pitc_portfolio" :is="toCachedResultComponent(item)" :key="item.id" />
            </template>
          </div>
        </div>
        <div v-else-if="activeTab === 'posts'" class="search-results-posts teaserbox__wrapper teaser--col--2">
          <InlineSpinner v-if="loading.posts"></InlineSpinner>
          <template v-else>
            <component v-for="item in results.posts" :is="toCachedResultComponent(item)" :key="item.id" class="teaserbox__item" />
          </template>
        </div>
        <div v-else-if="activeTab === 'events'" class="search-results-events events__wrapper">
          <div class="teaserbox__wrapper">
            <InlineSpinner v-if="loading.events"></InlineSpinner>
            <template v-else>
              <component v-for="item in results.events" :is="toCachedResultComponent(item)" :key="item.id" class="teaserbox__item" />
            </template>
          </div>
        </div>
        <div v-else-if="activeTab === 'trainings'" class="search-results-trainings teaserbox__wrapper teaser--col--2">
          <InlineSpinner v-if="loading.trainings"></InlineSpinner>
          <template v-else>
            <component v-for="item in results.trainings" :is="toCachedResultComponent(item)" :key="item.id" class="teaserbox__item" />
          </template>
        </div>

        <div v-if="results[activeTab]?.length === 0 && !loading[activeTab]">Keine Ergebnisse gefunden</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { InlineSpinner } from 'wly-theme-extensions';

const results = reactive({
  pages: [],
  pitc_portfolio: [],
  posts: [],
  events: [],
  trainings: [],
});

const loading = reactive({
  pages: false,
  posts: false,
  pitc_portfolio: false,
  events: false,
  trainings: false,
});

const props = defineProps({
  searchTerm: {
    type: String,
    default: () => null,
  },
});

const activeTab = ref('pages');
const totalResults = computed(() =>
  Object.values(results)
    .map((results) => results.length)
    .reduce((prev, length) => prev + length)
);

// Hold a state of all components (posts) to prevent re-rending the list when adding or filtering items.
let components = {};

const toCachedResultComponent = (post: Record<string, any>) => {
  if (!Object.hasOwn(components, post.id)) {
    components[post.id] = defineComponent({
      name: 'wte-search-result',
      template: post.content,
    });
  }

  return components[post.id];
};

let runAbortController = new AbortController();

const run = () => {
  runAbortController.abort();
  runAbortController = new AbortController();

  components = {};

  const requests = {
    pages: 'page',
    posts: 'post',
    pitc_portfolio: 'pitc_portfolio',
    events: 'event',
    trainings: 'training',
  };
  Object.keys(requests).forEach((group) => {
    loading[group] = true;

    fetch(`/wp-json/wte/paginator/${requests[group]}?per_page=30&lang=de&search_term=${props.searchTerm ?? ''}`, { signal: runAbortController.signal })
      .then((res) => res.json())
      .then((data) => {
        results[group] = data.posts;
        loading[group] = false;
      });
  });
};

onMounted(() => run());

defineExpose({
  run,
});
</script>


