import $ from 'jquery';

export class Parallax {

    constructor()
    {
        this.update();
        window.addEventListener('scroll', () => {
            this.update();
        });
    }

    scrollPosition = () => {
        return parseInt(window.scrollY || window.pageYOffset);
    }

    update()
    {
        const windowHeight = window.innerHeight;
        const scrollTop = $(window).scrollTop();
        const viewportBottom = scrollTop + windowHeight;

        document.querySelectorAll('.section__wrapper:not(:first-child), .footer__top, .footer__bottom').forEach(element => {
            const $section = $(element);
            const sectionOffset = $section.offset().top;
            const sectionHeight = $section.outerHeight();
            if (this.isElementInViewport($section, windowHeight, scrollTop, sectionHeight)) {
                const negativeValue = this.calculateNegativeValue(viewportBottom, sectionOffset) * -1;
                $section.css('margin-top', negativeValue + 'px');
                $section.addClass('reached');
            }
        });
    }

    simulate(scrollToElement)
    {
        const windowHeight = window.innerHeight;
        let elements = Array.from(document.querySelectorAll('.section__wrapper:not(:first-child), .footer__top, .footer__bottom'));

        let offset = 0;
        for (let element of elements) {

            const $section = $(element);
            const sectionOffset = $section.offset().top;
            let computedStyle = window.getComputedStyle(element);
            if (computedStyle) {
                offset -= Math.floor(parseInt(computedStyle.marginTop));
            }

            if (element === scrollToElement) {
                offset += this.calculateNegativeValue(element.getBoundingClientRect().top + this.scrollPosition() + windowHeight, sectionOffset) * -1;
                break;
            }

            offset += this.calculateNegativeValue(element.getBoundingClientRect().top + this.scrollPosition() + element.offsetHeight + windowHeight, sectionOffset) * -1;
        }
        return offset;
    }

    isElementInViewport($element, windowHeight, windowScroll, elementHeight)
    {
       return windowScroll > $element.offset().top - windowHeight &&
            windowScroll < $element.offset().top + elementHeight;
    }

    calculateNegativeValue(viewportBottom, sectionOffset)
    {
        const baseValue = Math.floor((viewportBottom - sectionOffset) * 0.13);
        const windowWidth = window.innerWidth;

        if (windowWidth < 601) return baseValue + 50;
        if (windowWidth < 768) return baseValue + 60;
        if (windowWidth < 1201) return baseValue + 70;
        return baseValue + 100;
    }
};
