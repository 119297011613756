import { onReady } from 'wly-theme-extensions';
import { InitializeForms } from './form.js';
import { updateActiveItem } from './navigation.ts';
import Accordion from './accordion.ts';
import Autosize from './autosize.ts';
import Swiper from './swiper.ts';
import lazySizes from 'lazysizes';
import Menu from './mmenu';
import Teich from './teich';
import {Parallax} from "./parallax.js";
import Scroll from './scroll-me-to-the-moon.js';

import './baguette-box';
import './googlemapsinfobox';
import './masonry';
import './lottie-player.js';

onReady(() => {
  Menu();
  updateActiveItem();
  lazySizes.init();
  Accordion();
  Autosize();
  Swiper();
  InitializeForms();
  Teich();
  Scroll();
  (new Parallax());
});
