// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    var wlySwiper = new Swiper('.swiper-container-wly', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        speed: 1400,
        spaceBetween: 30,
        autoplay: {
            delay: 7000,
        },
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    });


    var boxSwiper = new Swiper('.testimonial--swiper', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        speed: 1400,
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
            768: {
                slidesPerView: 2
            },
            1201: {
                spaceBetween: 50,
                slidesPerView: 2
            },
            1351: {
                slidesPerView: 3
            }
        },
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        }
    });


    var boxSwiper = new Swiper('.teaserbox--swiper', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        speed: 1400,
        slidesPerView: 1,
        spaceBetween: 0,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 10
            },
            1351: {
                slidesPerView: 3
            }
        },
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        }
    });


    var boxSwiper = new Swiper('.blog--swiper', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        speed: 1400,
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            1201: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            1351: {
                slidesPerView: 3,
                spaceBetween: 50
            }
        },
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        }
    });

    var boxSwiper = new Swiper('.tools--swiper', {
        modules: [Navigation, Pagination, Autoplay],
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        speed: 800,
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 30,
        breakpoints: {
            768: {
                slidesPerView: 3,
                slidesPerGroup: 2,
            },
            993: {
                slidesPerView: 4,
                slidesPerGroup: 3,
            },
            1201: {
                slidesPerView: 5,
                slidesPerGroup: 3,
            },
            1511: {
                slidesPerView: 6,
                slidesPerGroup: 4,
            }
        },
        grabCursor: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
        }
    });
};


