<template>
  <Paginator :config="{ ...config, saveStateToUrl: !hideFilter }" :hide-filter="hideFilter">
    <template v-slot="{ paginator, toPostComponent, hideFilter, noEntriesFoundText, loadMore }">
      <PostsFilter v-if="!hideFilter" :paginator="paginator" />

      <h3 v-if="title">{{ title }}</h3>

      <MasonryGrid v-if="showAsMasonry" :watch="[paginator.posts.value]" class="paginator-masonry">
        <div v-for="post in paginator.posts.value" :key="post.id">
          <component :is="toPostComponent(post.id)"></component>
        </div>
      </MasonryGrid>
      <div class="author-slider-blog" v-else-if="showAsGroupedByAuthor">
        <template v-for="(posts, author) in groupedPostsByAuthor(paginator).value">
          <div class="author-slider-blog__inner">
            <div class="author-wrapper" v-html="authorTemplates[author]"></div>
            <Slider class="author-posts-slider">
              <component class="swiper-slide post-slide" v-for="post in posts" :key="post.id" :is="toPostComponent(post.id)"></component>
            </Slider>
          </div>
        </template>
      </div>
      <div v-else-if="showAsSimpleSlider">
        <Slider
          class="blog-posts-slider"
          :config="{
            slidesPerView: 1,
            spaceBetween: 30,
            breakpoints: {
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1201: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1351: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            },
          }"
        >
          <component class="swiper-slide" v-for="post in paginator.posts.value" :key="post.id" :is="toPostComponent(post.id)"></component>
        </Slider>
      </div>
      <div v-else class="teaserbox__wrapper">
        <slot name="posts" v-bind="{ paginator, toPostComponent }">
          <component v-for="post in paginator.posts.value" :key="post.id" :is="toPostComponent(post.id)"></component>
        </slot>
      </div>

      <div v-if="noEntriesFoundText && !paginator.posts.value.length && !paginator.loading.value">{{ noEntriesFoundText }}</div>

      <div class="button__wrapper" v-if="loadMore && paginator.hasNextPage.value">
        <div class="button__ausrichtung--center">
          <div class="button button--secondary load--more" @click="paginator.loadMore()">
            <InlineSpinner v-if="paginator.loading.value" />
            <span>Mehr Laden</span>
          </div>
        </div>
      </div>
    </template>
  </Paginator>
</template>

<script setup lang="ts">
import PostsFilter from '@/js/vue/components/Filter.vue';
import { Paginator, InlineSpinner, MasonryGrid, PaginatorConfig } from 'wly-theme-extensions';
import { computed, PropType, toRaw } from 'vue';
import Slider from '@/js/vue/components/Slider.vue';

const props = defineProps({
  config: {
    type: Object as PropType<PaginatorConfig>,
    required: true,
  },
  authorTemplates: {
    type: [Array, Object],
    default: () => [],
  },
  title: {
    type: String,
  },
  viewMode: {
    type: String,
  },
  hideFilter: {
    type: Boolean,
    default: () => false,
  },
});

const showAsGroupedByAuthor = computed(() => props.viewMode === 'author');
const showAsMasonry = computed(() => props.viewMode === 'masonry');
const showAsSimpleSlider = computed(() => props.viewMode === 'slider');

const groupedPostsByAuthor = (paginator: any) =>
  computed(() => {
    const posts = !showAsGroupedByAuthor.value
      ? []
      : Object.groupBy(paginator.posts.value, ({ post }: any) => {
          return post.author_id;
        });

    return toRaw(posts);
  });
</script>
