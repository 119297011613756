import $ from 'jquery';
import {Parallax} from "./parallax.js";
import ImagesLoaded from 'imagesloaded';

export default () => {
    const isString = str => typeof str === 'string';
    const scrollPosition = () => {
        return parseInt(window.scrollY || window.pageYOffset);
    };

    const isValidSelector = selector => {
        if (!isString(selector)) return false;
        try {
            $(selector);
        } catch (error) {
            return false;
        }
        return true;
    };

    const isSamePage = href => {
        let urlObj;
        try {
            urlObj = new URL(href);
        } catch (_) {
            return true;
        }

        return !(window.location.hostname !== urlObj.hostname || window.location.pathname !== urlObj.pathname);
    };

    function scrollToAnchor(element) {
        const parallax = new Parallax();
        parallax.update();
        const closestSection = element.closest('.section__wrapper');
        const headerHeight = document.querySelector("header").offsetHeight;
        let closestSectionCalculatedMarginTop = 0;

        if (closestSection) {
            closestSectionCalculatedMarginTop = parallax.simulate(closestSection);
        }
        const targetOffsetTop =  ((Math.floor(element.getBoundingClientRect().top) + scrollPosition()) + closestSectionCalculatedMarginTop) - headerHeight - 100;

        $('html, body').animate(
            {
                scrollTop: targetOffsetTop,
            },
            500
        );
    }

    const urlHash = window.location.hash;
    if (urlHash && urlHash !== '') {
        // Prevent scroll
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 1);

        const hashIndex = urlHash.indexOf('#');
        if (hashIndex !== -1) {
            const hash = urlHash.substring(hashIndex);
            if (isValidSelector(urlHash) && isSamePage(urlHash)) {
                if ($(urlHash).length && urlHash !== '#wlymmenu' && urlHash !== '#') {
                    ImagesLoaded(document, () => {
                        scrollToAnchor(document.querySelector(urlHash));
                    });
                }
            }
        }
    }

    $(document).on('click', 'a[href]:not([href^="mailto:"], [href$="#"])', function (event) {
        const href = $(this).attr('href');
        // Extrahiere den Hash-Teil der URL
        const hashIndex = href.indexOf('#');
        if (hashIndex !== -1) {
            const hash = href.substring(hashIndex);
            if (isValidSelector(hash) && isSamePage(href)) {
                if ($(hash).length && hash !== '#wlymmenu' && hash !== '#') {
                    event.preventDefault();
                    scrollToAnchor(document.querySelector(hash));
                }
            }
        }
    });
};
