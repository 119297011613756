<template>
  <div class="page--standard page--search">
    <div class="section__wrapper bg--white">
      <div class="header__wrapper bg--blue block header-type--normal header-height--normal image--no">
        <div class="header__inner">
          <div class="header__content">
            <div class="container">
              <div class="row">
                <div class="col-12 col-xl-8 offset-xl-1">
                  <div class="header__content__inner">
                    <h1>Suche</h1>

                    <form action="#" @submit.prevent="run">
                      <div class="search-input-field">
                        <div class="form-floating">
                          <input name="s" id="s" type="search" class="form-control" placeholder=" " v-model="searchTerm" />
                          <label for="s">Suchbegriff...</label>
                        </div>

                        <button type="submit" id="searchsubmit" class="search__button" value="">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M9.659 19.31a9.635 9.635 0 0 0 5.921-2.034l6.385 6.385a1.2 1.2 0 0 0 1.698-1.697l-6.385-6.385a9.654 9.654 0 0 0-1.701-13.546 9.654 9.654 0 0 0-13.547 1.7C-1.24 7.945-.479 14.01 3.732 17.28a9.653 9.653 0 0 0 5.927 2.03ZM4.529 4.527a7.255 7.255 0 1 1 0 10.26 7.229 7.229 0 0 1 0-10.26Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block search__wrapper">
        <div class="container">
          <SearchResults ref="search" :search-term="searchTerm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SearchResults from '@/js/vue/components/SearchResults.vue';
import { onBeforeMount, onMounted, ref } from 'vue';

const searchTerm = ref('');
const search = ref();

const run = (e: Event) => {
  search.value.run();

  if ('URLSearchParams' in window) {
    const url = new URL(window.location.href);
    url.searchParams.set('q', searchTerm.value);
    history.pushState(null, '', url);
  }
};

onBeforeMount(() => {
  const urlParams = new URLSearchParams(location.href.split('?')[1]);
  const q = urlParams.get('q');
  if (q) {
    searchTerm.value = q;
  }
});
</script>
